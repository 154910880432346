<template>
  <div id="c2" class="border border-gray-400 rounded-b-xl p-2 h-4/5" :class="{'h-[65%]': DV_activeRenderMode == DV_renderModeDesktopNarrow}">
    <div v-show="DV_loading" class="my-32">
      <h2 class="text-xl text-gray-400">Please wait. Connecting...</h2>
      <svg class="h-12 mt-2 text-gray-400 spin-anticlock mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
      </svg>
    </div>

    <div v-show="!DV_loading" class="h-full">

      <!-- Body -->
      <div class="relative h-full">

        <!-- Import History -->
        <div v-if="Object.keys(DV_importRecords).length > 0" class="flex-row w-full grow mt-2 text-center border-2 border-slate-300 p-2">
          <table class="w-full text-center">
            <thead>
             <tr class="font-medium bg-gray-100 h-9">
              <th class="text-center" scope="col"><strong>File Name</strong></th>
              <th class="text-center" scope="col"><strong>Date Uploaded</strong></th>
              <th class="text-center" scope="col"><strong>File Management</strong></th>
             </tr>
            </thead>
            <tbody>
              <tr v-for="record in DV_importRecords" :key="record[0]">
                <td>{{record[4]}}</td>
                <td>{{moment.unix(record[3].seconds).format('MM-DD-YYYY h:mm A')}}</td>
                <td>
                  <div class="flex justify-center flex-wrap">
                    <button @click.stop="showViewCustomersModal(record[4], record)" class="btn btn-lime text-sm px-2 mr-2 mb-2">
                      View Customers
                    </button>
                    <button @click="showDeleteRecordModal(record[4], record[0])" class="btn btn-lime text-sm px-2 mr-2 mb-2">
                      Delete Record
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      
      <!-- View Customers Popup Modal-->  
      <PopupModal ref="viewCustomersPopupModal" :P_mediumModal="true" :P_showClose="true" @close-modal="closeViewCustomersModal">
        <!-- Heading -->
        <div slot="title"> <h3 class="font-bold">Contents of {{ DV_selectedViewingFileName }}</h3> </div>
       
        <template slot="body">
         
          <!-- Content Table -->
          <div class="flex-row w-full grow mt-2 text-center border-2 border-slate-300 p-2">
            <table class="w-full text-center">
              <thead>
                <tr class="font-medium bg-gray-100 h-9">
                  <th class="text-center" scope="col"><strong>Customer Name</strong></th>
                  <th class="text-center" scope="col"><strong>Phone Number</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="phone_number in DV_selectedViewingFileContents[2]">
                  <td>{{ getCustomerName(phone_number) }}</td>
                  <td>{{ formatPhoneNumber(phone_number) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </PopupModal>

      <!-- Delete Record Popup Modal-->
      <PopupModal ref="viewDeleteRecordModal" :P_smallModal="true" :P_showClose="true" @close-modal="closeDeleteRecordModal">
        <!-- Heading -->
        <div slot="title"> <h3 class="font-bold">Delete {{ DV_selectedViewingFileName }}?</h3> </div>

        <template slot="body">
          <p class="text-center mt-3">
            Are you sure you want to delete this record? This will delete the contacts added from this record.
          </p>
        </template>
        <template slot="footer">
          <div class="flex flex-row items-center justify-center">
            <button @click="deleteImportRecord(DV_selectedRecordToDelete)" @click.stop="closeDeleteRecordModal" class="btn btn-lime mr-2 w-20">Yes</button>
            <button @click="closeDeleteRecordModal" class="btn btn-red w-20">No</button>
          </div>
        </template>
      </PopupModal>


        <!-- Import Spreadsheet -->
        <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 p-2">
          <span class="w-1/2 flex items-center justify-center">
            Import Contacts From File
            <span class="tooltip m-2">
              ?
              <span class="tooltiptext whitespace-pre-line">Accepted File Types: (.csv, .xlsx, .xls)</span>
            </span>
          </span>
          <label class="drop-zone w-1/2 h-20" :class="{'drop-zone--over': DV_showDropzone}" @dragover.stop.prevent="DV_showDropzone = true" @dragleave.stop.prevent="DV_showDropzone = false" @drop="handleSpreadsheetImport">
            <span v-if="DV_importContent !== ''" class="text-sm text-gray-500">{{ DV_importFileName }}</span>
            <span v-else class="drop-zone__prompt text-sm text-gray-500">Drop file here or click to upload</span>
            <input autocomplete="off" id="file-upload-input" class="drop-zone__input hidden" ref="fileInput" type="file" @change="handleSpreadsheetImport" />
          </label>
        </div>

        <!-- Spreadsheet Mapping -->
        <div v-if="DV_importContent !== ''" class="flex-row w-full grow mt-2 text-center border-2 border-slate-300 p-2">
          <table class="w-full text-center">
            <thead>
             <tr class="font-medium bg-gray-100 h-9">
              <th class="text-center" scope="col"><strong>Required Fields</strong></th>
              <th class="text-center" scope="col"><strong>Column(s) from Import File</strong></th>
              <th class="text-center" scope="col"><strong>Sample Data</strong></th>
             </tr>
            </thead>
            <tbody>
              <tr>
                <td>First Name:</td>
                <td>
                  <select v-model="DV_selectedHeaderFirstName" id="first_name">
                    <option v-for="header in DV_spreadsheetHeaders" :key="header">{{ header }}</option>
                  </select>
                </td>
                <td>{{ DV_spreadsheetExampleData[DV_selectedHeaderFirstName] }}</td>
              </tr>
              <tr>
                <td>Last Name:</td>
                <td>
                  <select v-model="DV_selectedHeaderLastName" id="last_name">
                    <option v-for="header in DV_spreadsheetHeaders" :key="header">{{ header }}</option>
                  </select>
                </td>
                <td>{{ DV_spreadsheetExampleData[DV_selectedHeaderLastName] }}</td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>
                  <select v-model="DV_selectedHeaderPhoneNumber" id="phone_number">
                    <option v-for="header in DV_spreadsheetHeaders" :key="header">{{ header }}</option>
                  </select>
                </td>
                <td>{{ DV_spreadsheetExampleData[DV_selectedHeaderPhoneNumber] }}</td>
              </tr>
            </tbody>
          </table>

          <div class="flex w-full text-center justify-center mt-4">
            <button @click.stop="importContacts(DV_spreadsheetText)" class="btn btn-lime mr-8">Merge Data Fields</button>
            <button @click.stop="$emit('event-back')" class="btn btn-white">Cancel</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AWN from "awesome-notifications";
import dataValidator from "../../includes/bi1Datavalidation"
import { CR, RC } from "../../includes/bi2SuccessCodes"
import PopupModal from "../shared/PopupModal"
import importConsumerContacts from "../../includes/server_calls/p1s3/p1s3e49";
import deleteImportedContact from "../../includes/server_calls/p1s3/p1s3e50";
import _ from "lodash"
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"
import m3RenderingSettings from '../../mixins/m3RenderingSettings.js';
import moment from 'moment';

export default {
  name: "c10ImportContacts",

  props: ["P_importRecordUid", "P_viewType", "P_isModal", "P_loadedFrom"],

  components: { PopupModal },

  mixins: [ m2MiscDataFetchers, m3RenderingSettings ],

  data() {
    return {
      DV_lodash: _,
      DV_loading: true,
      DV_showViewType: "importNewContacts",
      DV_importRecordsView: "information",
      DV_importContactsRecord: {
        consumer_phone_numbers: [],
        remote_blob_path: "",
        date_uploaded: "",
      },
      DV_consumers: {},
      DV_importRecords: {},
      DV_importRecordsLastChanged: null,
      DV_importContent: "",
      DV_spreadsheetText: "",
      DV_importFileName: "",
      DV_spreadsheetHeaders: [],
      DV_spreadsheetExampleData: [],
      DV_selectedHeaderFirstName: "",
      DV_selectedHeaderLastName: "",
      DV_selectedHeaderPhoneNumber: "",
      DV_selectedViewingFileName: "",
      DV_selectedViewingFileContents: [],
      DV_selectedRecordToDelete: "",
      DV_showDropzone: false,
      DV_loadedFrom: "",
      moment: moment,
    };
  },

  mounted() {
    const CI = this;

    CI.startImportedConsumerRecordsListener()
    CI.DV_showViewType = this.P_viewType || "importNewContacts";
    CI.DV_loadedFrom = this.P_loadedFrom || "";
  
    CI.DV_consumers =  window.G_bi2_data.IV_consumer_contacts.value;
    CI.DV_importRecordsLastChanged = window.G_bi2_data.IV_imported_contacts_history_last_changed.value;
    
    CI.DV_importRecords = window.G_bi2_data.IV_imported_contacts_history.value
    
    if (this.P_viewType === "importNewContacts") {}
    CI.DV_loading = false;  
  },

  computed: {},

  methods: {
    startImportedConsumerRecordsListener(retry_count=0) {
      const CI = this;
      if (retry_count >= 10) { return; }

      if (!window.G_bi2_data.IV_active_employee_info.business_uid || !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) {
        setTimeout( function() {CI.startImportedConsumerRecordsListener(++retry_count)}, 500);
        return
      }

      window.G_bi2_data.startImportedContactsHistoryListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid
      )

    },

    handleSpreadsheetImport(e) {
      const CI = this;
      const inputElement = e.target;
      CI.DV_dropZoneElement = inputElement.closest(".drop-zone");

      var files = [];

      if (e.target.tagName == 'LABEL') {
        files = e.dataTransfer.files;
      } else if (e.target.tagName == 'INPUT') {
        files = e.target.files;
      }

      if (!files || !files[0]) { return; }

      if (files.length > 1) {
        this.$awn.alert("Please choose only one file per import.");
        return;
      }

      // validate size
      var file = files[0];
      if (file.size > (10*1024*1024)) {
        this.$awn.alert("File size must be less than 10 megabytes.");
        return;
      }

      // validate format
      const supportedFileTypes = [".csv", ".xlsx", ".xls"];

      if (supportedFileTypes.some((fileType) => file.name.endsWith(fileType))) {} else {
        this.$awn.alert("File must be in (.csv, .xlsx, .xls) format.")
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        CI.DV_importContent = reader.result;
        CI.DV_importFileName = file.name;
      }

      this.handleSpreadsheetReading(file);

      return;
    },

    handleSpreadsheetReading(file) {
      const CI = this;
      const reader = new FileReader();

      reader.onload = (obj) => {
        const csvContent = obj.target.result
        CI.DV_spreadsheetHeaders = this.getSpreadsheetHeaders(csvContent);
        CI.DV_spreadsheetExampleData = this.getCSVExampleData(csvContent);
        CI.DV_spreadsheetText = csvContent;
      };
      reader.readAsText(file);
    },

    getSpreadsheetHeaders(fileContent) {
      const lines = fileContent.split('\n');

      if (lines.length > 0) {
        // Assuming the first line contains headers
        const headers = lines[0].split(',');
        return headers.map(header => header.trim());
      } else {
        return [];
      }
    },

    getCSVExampleData(fileContent) {
      const CI = this;

      const rows = fileContent.trim().split('\n');

      if (rows.length < 2) {
        throw new Error("Invalid CSV content. Must have at least one header row and one data row.");
      }

      const header = rows[0].split(',').map((field) => field.trim());

      const csvRows = rows
          .slice(1) // Skip header row
          .map((row) => {
            const values = row.split(',').map((value) => value.trim());
            const rowData = {};

            header.forEach((field, index) => {
              rowData[field] = values[index];
            });

            return rowData;
          });

      // Create an object where keys are field names and values are arrays containing up to three distinct values
      const resultObject = {};
      header.forEach((field) => {
        const fieldValues = csvRows.map((row) => row[field]).filter((value, index, self) => self.indexOf(value) === index).slice(0, 3);
        resultObject[field] = fieldValues.join(", ");

        // Set variables based on header names
        if (field === "First Name") {
          CI.DV_selectedHeaderFirstName = field;
        } else if (field === "Last Name") {
          CI.DV_selectedHeaderLastName = field;
        } else if (field === "Phone Number") {
          CI.DV_selectedHeaderPhoneNumber = field;
        }
      });

      return resultObject;
    },

    importContacts(spreadsheetContent) {
      const CI = this;

      // get selected headers
      const rows = spreadsheetContent.trim().split('\n');

      if (rows.length < 2) {
        throw new Error("Invalid CSV content. Must have at least one header row and one data row.");
      }

      const header = rows[0].split(',').map((field) => field.trim());

      const csvRows = rows
        .slice(1) // Skip header row
        .map((row) => {
          const values = row.split(',').map((value) => value.trim());
          const rowData = {};

          header.forEach((field, index) => {
            rowData[field.toLowerCase()] = values[index];
        });

          return rowData;
        });

      // Create a map with the specified format
      const resultArray = csvRows.map((row) => {
        const resultObject = {
          'first_name': row[CI.DV_selectedHeaderFirstName.toLowerCase()],
          'last_name': row[CI.DV_selectedHeaderLastName.toLowerCase()],
          'phone_number': row[CI.DV_selectedHeaderPhoneNumber.toLowerCase()],
        };
        return resultObject;
      });

      importConsumerContacts(
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        JSON.stringify(resultArray),
        CI.DV_importContent,
        CI.DV_importFileName,
      ).then((resp) => {
        CI.$awn.success(`Contacts Added.`);
      })
      .catch((error) => {
        const errorMessage = error.message;
        CI.$awn.alert(`Importing Consumer Contacts Failed.` + errorMessage);
      });

      this.$emit('event-back');
    },

    deleteImportRecord(fs133_doc_id) {
      const CI = this;

      delete window.G_bi2_data.IV_imported_contacts_history.value[fs133_doc_id];
      CI.DV_importRecords = window.G_bi2_data.IV_imported_contacts_history.value;

      deleteImportedContact(
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          fs133_doc_id,
      ).then((resp) => {
        CI.$awn.success(`Import Record and its Contacts are Deleted.`);
      })
      .catch((error) => {
        const errorMessage = error.message;
        CI.$awn.alert(`Deleting the Imported Record Failed.` + errorMessage);
      });
    },

    showViewCustomersModal(file_name, file_contents) {
      const CI = this;

      CI.DV_selectedViewingFileName = file_name;
      CI.DV_selectedViewingFileContents = file_contents;

      window.addEventListener('keydown', this.closeViewCustomersModal)

      this.$refs.viewCustomersPopupModal.show();
    },

    closeViewCustomersModal(event) {
      const CI = this;

      CI.DV_selectedViewingFileName = "";
      CI.DV_selectedViewingFileContents = "";

      window.removeEventListener('keydown', this.closeViewCustomersModal)
      
      this.$refs.viewCustomersPopupModal.hide();
    },

    showDeleteRecordModal(file_name, fs133_doc_id) {
      const CI = this;

      CI.DV_selectedViewingFileName = file_name;
      CI.DV_selectedRecordToDelete = fs133_doc_id;
 
      window.addEventListener('keydown', this.closeDeleteRecordModal);

      this.$refs.viewDeleteRecordModal.show();
    },
 
    closeDeleteRecordModal(event) {
      const CI = this;

      CI.DV_selectedViewingFileName = "";

      window.removeEventListener('keydown', this.closeDeleteRecordModal)

      this.$refs.viewDeleteRecordModal.hide();
    },
       
    getCustomerName(phone_number) {
      const CI = this;

      for (const fs4_doc_id in CI.DV_consumers) {
        const consumer_data = CI.DV_consumers[fs4_doc_id];

        const consumer_phone_number = consumer_data[12].phone_number;
        const first_name = consumer_data[1];
        const last_name = consumer_data[2];

        const consumer_name = first_name + " " + last_name;       
        if (phone_number === consumer_phone_number) {
         return consumer_name;
        }
      }
    },
  }, 
  
  watch: {
    DV_importRecordsLastChanged: { 
      handler() {
        const CI = this;

        CI.DV_importRecordsLastChanged = window.G_bi2_data.IV_imported_contacts_history_last_changed.value;

        const filteredimportRecords = {};
        Object.keys(CI.DV_importRecords).forEach(key => {
          if (key !== 'null' && key !== null) {
            filteredimportRecords[key] = CI.DV_importRecords[key];
          }
        });
        
        window.G_bi2_data.IV_imported_contacts_history.value = filteredimportRecords;
        CI.DV_importRecords = filteredimportRecords;
      },
    },
  }
};
</script>

<style>
.item1 { grid-area: primary; }
.item2 { grid-area: second; }
.item3 { grid-area: third; }

.grid-container {
  display: grid;
  grid-template-areas:
    'primary third third third'
    'third';
  gap: 10px;
}
</style>
