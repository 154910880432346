<template>
  <div id="c1" class="border border-gray-400 shadow-sm p-3 rounded-xl h-full" :style="{'height': C_showingFullPage ? '100vh' : '100%'}">
    <!-- Desktop view -->
    <div v-if="DV_loadingConversations" :style="{'height': C_showingFullPage ? '100vh' : '100%'}" class="text-2xl flex items-center justify-center">
      Loading...
    </div>

    <div v-else-if="DV_conversationNotFound" :style="{'height': C_showingFullPage ? '100vh' : '100%'}" class="text-2xl flex items-center justify-center">
      Unable to find this conversation record!
      <br><br>
      Please, reload the page or contact support if the issue persist.
    </div>
    
    <div v-else-if="DV_activeConversation" class="relative md:block hidden h-full" style="height:96%">
      <div>
        <p class="text-left text-xl my-1 w-[90%] truncate !mb-0">
          {{C_activeConversationTitle}}<span class="dot larger ml-2" :class="DV_activeConversation.activity_status"></span>

          <span v-show="C_identifyVerificationLogo != '' && C_statusText != ''">
            <img :src="C_identifyVerificationLogo" class="w-10 inline ml-2"/>
            <button
              v-show="DV_viewVerificationImages == false"
              @click="DV_viewVerificationImages = true"
              :disabled="DV_verificationFileLinks.length === 0" 
              class="ml-2 font-bold btn btn-lime w-auto chat-action-button">{{ C_statusText }}
            </button>
          </span>
        </p>

        <span v-if="!C_showingFullPage" class="absolute top-0 right-0">
          <svg @click="openFullScreen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
          </svg>
        </span>

        <div class="flex flex-row flex-wrap py-2 mb-1 items-center">
          <button v-if="C_businessType == 1" @click.stop="showQuotesPopupModal" class="mt-2 btn btn-lime w-auto mr-1 chat-action-button">Payment Links</button>
          <button v-else-if="C_businessType == 2" @click.stop="requestReviewLinkURL" class="mt-2 btn btn-lime w-auto mr-1 chat-action-button">Review Request</button>
          <button @click.stop="showSelectCallingPhoneNumber" class="mt-2 btn btn-lime w-auto mr-1 chat-action-button">Call Customer</button>
          <button @click.stop="showInviteTeamMemberModal" type="button" class="mt-2 btn btn-lime w-auto mr-1 chat-action-button">Invite Team Member</button>
          <button @click.stop="moveToQueue" type="button" class="mt-2 btn btn-lime w-auto mr-1 chat-action-button">Move To Queue</button>
          <button @click.stop="moveToFinish" type="button" class="mt-2 btn btn-lime w-auto mr-1 chat-action-button">Move To Finished</button>
        </div>
 
        <!-- Header tabs -->
        <ul class="flex flex-wrap w-full">
          <li class="w-[20%] !mt-4 ">
            <a
              @click.stop="DV_activeTab = 'live-chat'"
              :class="{ active: DV_activeTab == 'live-chat' }"
              class="tab-header-button w-full truncate"
              >Customer Chat</a
            >
          </li>
          <li class="w-[20%] !mt-4">
            <a
              @click.stop="DV_activeTab = 'profile'"
              :class="{ active: DV_activeTab == 'profile' }"
              class="tab-header-button w-full truncate"
              >Profile</a
            >
          </li>
          <li class="w-[20%] !mt-4">
            <a
              @click.stop="DV_activeTab = 'previous-conversation'"
              :class="{ active: DV_activeTab == 'previous-conversation' }"
              class="tab-header-button w-full truncate"
              >Conversation History</a
            >
          </li>
          <li class="w-[20%] !mt-4">
            <a
              @click.stop="DV_activeTab = 'feedback'"
              :class="{ active: DV_activeTab == 'feedback' }"
              class="tab-header-button w-full truncate"
              >Customer Notes</a
            >
          </li>
          <li class="w-[20%] !mt-4">
            <a
              @click.stop="DV_activeTab = 'yard-only-chat'"
              :class="{ active: DV_activeTab == 'yard-only-chat' }"
              class="tab-header-button w-full truncate"
              >Yard Only Chat</a
            >
          </li>
        </ul>
      </div>

      <c11ImageViewer 
        v-if="DV_viewVerificationImages == true"
        :P_imageLinks="DV_verificationFileLinks"
        @close="DV_viewVerificationImages = false"
      />
      <c7CustomerChat
        v-if="DV_activeConversation && DV_activeTab == 'live-chat'"
        @show-image="$emit('show-image')"
        :is-modal="isModal"
        :P_consumerUid="DV_activeConversation.consumer_uid" 
        :P_conversationUid="DV_activeConversation.conversation_uid"
      />
      <c2CustomerProfile 
        v-if="DV_activeConversation && DV_activeTab == 'profile'" 
        P_viewType="editView"
        P_loadedFrom="chat"
        :P_consumerUid="DV_activeConversation.consumer_uid"
        @event-back="DV_activeTab = 'live-chat'"
      />
      <c3CustomerFeedback 
        v-if="DV_activeConversation && DV_activeTab == 'feedback'" 
        :P_consumerUid="DV_activeConversation.consumer_uid" 
        :P_conversationUid="DV_activeConversation.conversation_uid"
      />
      <c8RecyclerOnlyChat 
        v-if="DV_activeConversation && DV_activeTab == 'yard-only-chat'" 
        :P_consumerUid="DV_activeConversation.consumer_uid" 
        :P_conversationUid="DV_activeConversation.conversation_uid"
      />
      <c4CustomerHistory 
        v-if="DV_activeConversation && DV_activeTab == 'previous-conversation'" 
        :P_consumerUid="DV_activeConversation.consumer_uid" 
        :P_isChatModuleParent="true"
      />
    </div>

    <div v-else class="text-xl justify-center mt-72">
      <span class="w-full">Select a conversation from 'My Conversations' in the right panel,</span>
      <br>OR<br>
      <span class="w-full">Start a new conversation to engage with your customer!</span>
      <br><br>
      <span class="w-full">Remember, you can find your queue for online widget requests in the left panel.</span>
      <br>
      <span class="w-full mt-6 block">Also, don't forget to check out 'Contacts' in the top-left menu for easy access to your contacts!</span>
    </div>

    <c1c8ConversationQuotes
      v-if="DV_activeConversation"
      :P_conversationUuid="C_activeConversationUid" 
      ref="quotesPopupModal"
    />
    
    <!-- Mobile view -->
    <div class="md:hidden block">
      <c7CustomerChat
        v-if="DV_activeConversation && DV_activeTab == 'live-chat'"
        @show-image="$emit('show-image')"
        :P_consumerUid="DV_activeConversation.consumer_uid" 
        :P_conversationUid="DV_activeConversation.conversation_uid"
      />
    </div>

    <PopupModal ref="finishConversationModal" :P_mediumModal="true" :P_showClose="true" @close-modal="closeFinishConversationModal">
      <!-- Heading -->
      <div slot="title"> <h3 class="font-bold">Finish Conversation</h3> </div>

      <template slot="body">

        <p class="text-center my-3">
          Please rate the interactions you had with the customer during this
          conversation.
        </p>

        <!-- Rating button -->
        <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
          <button @click.stop="DV_finishConversationRating = 100" :class="{'active': DV_finishConversationRating == 100}" type="button" class="filter-button left">
            Amazing
          </button>
          <button @click.stop="DV_finishConversationRating = 85" :class="{'active': DV_finishConversationRating == 85}" type="button" class="filter-button center">
            Good
          </button>
          <button @click.stop="DV_finishConversationRating = 70" :class="{'active': DV_finishConversationRating == 70}" type="button" class="filter-button center">
            Average
          </button>
          <button @click.stop="DV_finishConversationRating = 60" :class="{'active': DV_finishConversationRating == 60}" type="button" class="filter-button center">
            Below Average
          </button>
          <button @click.stop="DV_finishConversationRating = 50" :class="{'active': DV_finishConversationRating == 50}" type="button" class="filter-button right">
            Bad
          </button>
        </span>
      </template>

      <template slot="footer">
        <div class="flex flex-row items-center justify-center">
          <button @click="finishConversation(true)" class="btn btn-lime mr-2 w-52">Skip Rating and Finish</button>
          <button @click="finishConversation(false)" class="btn btn-lime w-52">Rate and Finish</button>
        </div>
      </template>
    </PopupModal>

    <mod6InviteTeamMemberToConversation
      v-if="DV_activeConversation"
      ref="inviteTeamMemberModal"
      :P_consumerUid="DV_activeConversation.consumer_uid" 
      :P_conversationUid="DV_activeConversation.conversation_uid">
    </mod6InviteTeamMemberToConversation>

    <PopupModal ref="selectCallingPhoneNumber" :P_mediumModal="true" :P_showClose="true" @close-modal="closeSelectCallingPhoneNumberModal">
      <div slot="title"> <h3 class="font-bold">What phone number do you want to call?</h3> </div>

      <template slot="body">
        <div class="mt-4">
          <div class="flex justify-center">
            <span v-for="number in DV_consumerNumbers" class="flex items-center mr-5">
              <input type="radio" class="mr-2 cursor-pointer" v-model="DV_callingNumber" :value="number" :id="`call-${number}`">
              <label :for="`call-${number}`" class="cursor-pointer">{{ number }}</label>
            </span>
          </div>

          <div class=" flex justify-center mt-5">
            <button class="btn btn-lime mr-2 w-52" @click="callCustomer(DV_callingNumber)">Call Customer</button>
            <button class="btn btn-lime mr-2 w-52" @click="closeSelectCallingPhoneNumberModal()">Discard</button>
          </div>

        </div>

      </template>
    </PopupModal>
    
  </div>
</template>

<script>
import Vue from "vue";
import AWN from "awesome-notifications";
import { fs43Writer } from '../../includes/firestore_writers/fs43_writer.ts';
import { fs45Writer } from '../../includes/firestore_writers/fs45_writer.ts';
import { fs46Writer } from '../../includes/firestore_writers/fs46_writer.ts';
import { fs6Writer } from '../../includes/firestore_writers/fs6_writer.ts';
import { fs27Writer } from '../../includes/firestore_writers/fs27_writer.ts';
import { fs93Writer } from '../../includes/firestore_writers/fs93_writer.ts';
import { fs112Writer } from '../../includes/firestore_writers/fs112_writer.ts';

import c4CustomerHistory from "./c4CustomerHistory.vue";
import c8RecyclerOnlyChat from "./c8RecyclerOnlyChat.vue";
import c3CustomerFeedback from "./c3CustomerFeedback.vue";
import c2CustomerProfile from "./c2CustomerProfile.vue";
import c7CustomerChat from "./c7CustomerChat.vue";
import c1c8ConversationQuotes from "./c1c8ConversationQuotes.vue";
import mod6InviteTeamMemberToConversation from "../shared/mod6InviteTeamMemberToConversation.vue";
import PopupModal from "../shared/PopupModal"
import rateConsumerInteraction from "../../includes/server_calls/p1s3/p1s3e8"
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"
import sendMessageToConsumer from "../../includes/server_calls/p1s5/p1s5e2"
import retrieveIdentityVerificationDocuments from "../../includes/server_calls/p1s7/p1s7e6"
import processReviewRequest from "../../includes/server_calls/p1s3/p1s3e55";
import moment from "moment"
import c11ImageViewer from "./c11ImageViewer.vue";

export default {
  name: "c1ChatModule",

  components: {
    c2CustomerProfile,
    c7CustomerChat,
    c3CustomerFeedback,
    c8RecyclerOnlyChat,
    c4CustomerHistory,
    c1c8ConversationQuotes,
    PopupModal,
    mod6InviteTeamMemberToConversation,
    c11ImageViewer
  },

  mixins: [ m2MiscDataFetchers ],

  props: ["isModal", "conversation", "P_activeConversationUid"],

  data() {
    return {
      DV_activeTab: "live-chat",
      DV_businesses: window.G_bi2_data.IV_businesses,
      DV_activeBusinessUid: "",
      DV_activeConversation: null,
      DV_conversationNotFound: false,
      DV_loadingConversations: true,
      DV_finishConversationRating: null,
      DV_queueConversationsListItemIndexes: window.G_bi2_data.IV_al_consumer_conversation_details,
      DV_consumerNumbers: [],
      DV_callingNumber: "",
      DV_consumerContacts: window.G_bi2_data.IV_consumer_contacts.value,
      DV_verificationFileLinks: [],
      DV_previousConsumerUID: "",
      DV_viewVerificationImages: false,
    };
  },

  mounted() {
    this.setActiveConversation();
    this.startBusinessDataListener();
  },

  computed: {
    C_businessType() {
      if (Object.entries(this.DV_businesses).length == 0) { return 0; }
      
      return this.DV_businesses[window.G_bi2_data.IV_active_employee_info.business_uid].business_type;
    },
    
    C_showingFullPage() {
      return this.$route.name == "liveChat"
    },

    C_activeConversationTitle() {
      let title = ""
      const consumer_and_conversation_uuid = this.DV_activeConversation.consumer_uid + "-" + this.DV_activeConversation.conversation_uid
      
      if (this.DV_activeConversation.phone_number) { 
        title += `${this.formatPhoneNumber(this.DV_activeConversation.phone_number)}` 
      }

      if (this.DV_activeConversation.consumer_name && !this.DV_activeConversation.consumer_name.toLowerCase().includes("test") && !this.DV_activeConversation.consumer_name.toLowerCase().includes("unknown")) { 
        title += ` | ${this.DV_activeConversation.consumer_name}` 
      }

      if (this.DV_activeConversation.business_name && !this.DV_activeConversation.business_name.toLowerCase().includes("test") && !this.DV_activeConversation.business_name.toLowerCase().includes("unknown")) { 
        title += ` | ${this.DV_activeConversation.business_name}` 
      }

      if (consumer_and_conversation_uuid in window.G_bi2_data.IV_consumer_conversation_details.value) {
        let data = window.G_bi2_data.IV_consumer_conversation_details.value[consumer_and_conversation_uuid]

        if (data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array] && data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array][this.DV_queueConversationsListItemIndexes.ma1i_vehicle_make_name]) {
          title += ` | ${data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array][this.DV_queueConversationsListItemIndexes.ma1i_vehicle_make_name]}`  
        }

        if (data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array] && data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array][this.DV_queueConversationsListItemIndexes.ma1i_vehicle_model_name]) {
          title += ` ${data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array][this.DV_queueConversationsListItemIndexes.ma1i_vehicle_model_name]}`
        }

        if (data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array] && data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array][this.DV_queueConversationsListItemIndexes.ma1i_vehicle_year]) {
          title += ` ${data[this.DV_queueConversationsListItemIndexes.ma1_vehicles_and_parts_array][this.DV_queueConversationsListItemIndexes.ma1i_vehicle_year]}`
        }
      }

      return title;
    },

    C_activeConversationUid() {
      // set from the url if chat only opens.
      let conversation_uid = this.P_activeConversationUid;

      if (this.$route.name == "liveChat" && this.$route.query["uid"]) {
        conversation_uid = this.$route.query["uid"];
      }

      return conversation_uid;
    },

    C_identifyVerificationLogo() {
      let logo = ""

      if (this.DV_activeConversation.consumer_uid in this.DV_consumerContacts) {
        let details = this.DV_consumerContacts[this.DV_activeConversation.consumer_uid][window.G_bi2_data.IV_al_consumer_contacts.verified_outputs]

        if (Object.keys(details).length == 0 || details.matched_profile == undefined) {return logo}

        if (details.matched_profile === 2) {
          logo = require('../../assets/images/conversation_status_icons/identity_verified.png')
        } else if (details.matched_profile === 1) {
          logo = require('../../assets/images/conversation_status_icons/identity_failed.png')
        }
      }

      return logo
    },

    C_statusText() {
      let statusText = ''
      this.DV_viewVerificationImages = false;

      if (this.DV_activeConversation.consumer_uid in this.DV_consumerContacts) {
        let details = this.DV_consumerContacts[this.DV_activeConversation.consumer_uid][window.G_bi2_data.IV_al_consumer_contacts.verified_outputs]
        
        if (Object.keys(details).length == 0 || details.matched_profile == undefined || details.time_verified == undefined) {return statusText}

        if (details.matched_profile === 2) {
          this.retrieveVerificationDocuments();
          const currentDate = moment();
          const anotherDate = moment.unix(details.time_verified.seconds);
          statusText = currentDate.diff(anotherDate, 'days');

          if (statusText == 0) {
            statusText = `Verified on ${anotherDate.format('MM-DD-YYYY')}`
          } else {
            statusText = `Verified ${ statusText } ${ statusText > 1 ? 'Days' : 'Day' } Ago`
          }
        } else if (details.matched_profile === 1) {
          this.retrieveVerificationDocuments();
          statusText = `Verification Failed`
        }
      }

      return statusText
    }
  },

  methods: {
    startConversationHistoryListener(retry_count=0) {
      const CI = this;

      if (!this.DV_activeConversation) { 
        setTimeout(function() { CI.startConversationHistoryListener(0) }, 100);
        return; 
      }

      if (retry_count > 20) { return; }

      if (!this.DV_activeConversation.consumer_uid) {
        setTimeout(function() { CI.startConversationHistoryListener(++retry_count) }, 100);
        return;
      }

      window.G_bi2_data.startConsumerConversationHistoryIndexListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.DV_activeConversation.consumer_uid
      );
    },

    requestReviewLinkURL(event) {
      const CI = this;
      const value = event.target.checked;

      let notifier = new AWN();
      notifier.confirm(
        // Label
        "Are you sure you want to send this customer a review request?",
        // On OK
        function() {
          CI.requestReviewLink();
        },

        // On Cancel
        function() {
          CI.$nextTick(() => {});
        },

        // Other options
        {
          labels: {
            confirm: 'Request Review',
            confirmOk: "Yes",
            confirmCancel: "No"
          }
        }
      );
    },
    startBusinessDataListener(retry_count=0) {
      const CI = this;

      CI.DV_activeBusinessUid = window.G_bi2_data.IV_loggedin_user_business_ids.value[0];
      
      // If not business owner check for admin employee
      if (!CI.DV_activeBusinessUid) {
        const employeeInfo = window.G_bi2_data.IV_active_employee_info;
        const employeeBusinessUid = employeeInfo.business_uid;
        const permissions = employeeInfo.permissions;
        
        if (employeeBusinessUid && permissions && (permissions.admin === true || permissions.manager === true)) {
          CI.DV_activeBusinessUid = employeeInfo.business_uid  
        }
      }
      const activeBusiness = window.G_bi2_data.IV_businesses['value'][CI.DV_activeBusinessUid];

      // Active business data
      if (!activeBusiness) { 
        if (retry_count >= 10) { 
          CI.DV_businessNotFound = true;
          CI.DV_businessesLoading = false;
          return; 
        }
        setTimeout(function() {
          CI.startBusinessDataListener(++retry_count)
        }, 500);
        return; 
      }
    },

    openFullScreen(key) {
      let routeData = this.$router.resolve({ path: "/liveChat?uid="+this.C_activeConversationUid });
      window.open(routeData.href, "_blank");
    },

    callCustomer(phone_number) {
      this.closeSelectCallingPhoneNumberModal();
       window.open(`tel:+1${phone_number}`, "_self");
    },

    showInviteTeamMemberModal() {
      this.$refs.inviteTeamMemberModal.showSelf();
    },

    setActiveConversation(retry_count=0) {
      const CI = this;

      let conversation_uid = this.C_activeConversationUid;

      if (!conversation_uid) { 
        this.DV_loadingConversations = false;
        this.DV_activeConversation = null;
        return; 
      }

      if (conversation_uid in window.G_bi2_data.IV_consumer_conversations.value == false) {
        if (retry_count >= 500) {
          this.DV_conversationNotFound = true;
          this.DV_loadingConversations = false;
          return;
        }

        setTimeout(function() { CI.setActiveConversation(++retry_count)}, 500);
        return;
      }


      this.DV_activeConversation = window.G_bi2_data.IV_consumer_conversations.value[conversation_uid];
      this.DV_loadingConversations = false;
      this.DV_conversationNotFound = false;

      window.G_bi2_data.startConsumerConversationMessagesListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.DV_activeConversation.consumer_uid,
        this.DV_activeConversation.conversation_uid
      )

      this.startConversationHistoryListener(0);
    },

    moveToQueue() {
      const CI = this;

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure, you want to move this chat back to queue?",
        // On OK
        function() {
          let conversation_uid = CI.DV_activeConversation.conversation_uid;
          let consumer_uid = CI.DV_activeConversation.consumer_uid;
          let currentEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;
          let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
          let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

          // remove entry from fs45
          fs45Writer(
            "d",
            currentBusinessUid, 
            currentRecyclerLocationUid,
            consumer_uid,
            conversation_uid
          )

          // remove entry from fs46
          fs46Writer(
            "d",
            currentBusinessUid, 
            currentRecyclerLocationUid, 
            currentEmployeeUid,
            consumer_uid,
            conversation_uid
          )
          
          // add to fs43
          fs43Writer(
            "n",
            currentBusinessUid, 
            currentRecyclerLocationUid,
            consumer_uid,
            conversation_uid,
            {
              conversation_uid: conversation_uid,
              consumer_uid: consumer_uid
            }
          )

          // Update fs6 entry
          fs6Writer(
            "e", 
            currentBusinessUid, 
            currentRecyclerLocationUid, 
            consumer_uid, 
            conversation_uid,
            { conversation_status_codes: "a" }
          );

          CI.$emit("close-chat");
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Move Back to Queue',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    moveToFinish() {
      this.$refs.finishConversationModal.show();
    },

    finishConversation(skip_rating=false) {
      const CI = this;

      if (!skip_rating && !CI.DV_finishConversationRating) {
        this.$awn.alert("Please select a rating first.");
        return;
      }

      if (skip_rating) {
        CI.DV_finishConversationRating = null; 
      }

      let consumer_uid = CI.DV_activeConversation.consumer_uid;
      let conversation_uid = CI.DV_activeConversation.conversation_uid;
      let currentEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;
      let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
      let phoneNumber = CI.DV_activeConversation.phone_number
      let recyclerLocation = window.G_bi2_data.IV_recyclers.value[currentRecyclerLocationUid];

      var popup_options = {
      labels: {
          success: "Conversation rated successfully.",
          async: "Rating Conversation",
          alert: "Failed to rate the conversation please reload page and try again.",
        },
        position: "center",
        duration: 3000,
      };

      // Update fs6 entry
      fs6Writer(
        "e", 
        currentBusinessUid, 
        currentRecyclerLocationUid, 
        consumer_uid, 
        conversation_uid,
        { 
          conversation_status_codes: 'z', 
          chat_finished_timestamp: new Date(),
          conversation_consumer_rating: CI.DV_finishConversationRating
        }
      );

      // Remove fs27 entry
      fs27Writer(
        "d",
        currentBusinessUid, 
        currentRecyclerLocationUid, 
        consumer_uid, 
        conversation_uid,
      );

      // Remove fs43 entry
      fs43Writer(
        "d",
        currentBusinessUid, 
        currentRecyclerLocationUid,
        consumer_uid,
        conversation_uid
      );
      
      // Remove fs6 entry
      fs46Writer(
        "d",
        currentBusinessUid,
        currentRecyclerLocationUid, 
        currentEmployeeUid,
        consumer_uid,
        conversation_uid
      )

      // Remove from fs93
      fs93Writer(
        "d", 
        currentBusinessUid, 
        currentRecyclerLocationUid, 
        phoneNumber, 
        conversation_uid
      );

        //put a message in chat letting the consumer knowing the sales person has left the conversation
        if (recyclerLocation.sms_only_messaging === false) {
          const employee_uid = window.G_bi2_data.IV_active_employee_info.employee_uid;
          const first_name = window.G_bi2_data.IV_recycler_employees.value[employee_uid]["first_name"];
          const last_name = window.G_bi2_data.IV_recycler_employees.value[employee_uid]["last_name"];
          
          let join_message = "[FORCE_SENDER_TYPE_4]" + first_name + " " + last_name + " has left the conversation"
          let params = {
            recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
            recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            employee_uid: employee_uid,
            consumer_uid: consumer_uid,
            conversation_uid: conversation_uid,
            message_type: 1,
            content: join_message ,
            file_name: ""
          }

          sendMessageToConsumer(params);
      }

      let rating = CI.DV_finishConversationRating
      CI.closeFinishConversationModal();
      CI.$emit("close-chat");

      // make call to rating in background, but notify the user if it fails
      if (!skip_rating) {
        let notifier = new AWN(popup_options);        
        notifier.async(
          rateConsumerInteraction(
            currentBusinessUid,
            currentRecyclerLocationUid,
            currentEmployeeUid,
            consumer_uid,
            conversation_uid,
            +rating
          ),
          (resp) => {       
          },
          (error) => {
            CI.$awn.alert(error.error_title)
            CI.DV_creatingBusiness = false;
          }
        );
        return;
      }

      // Delete all notifications from fs112
      // Delete all message type notifications
      var consumer_and_conversation_uuid = `${consumer_uid}-${conversation_uid}`
      fs112Writer("d", currentBusinessUid, currentRecyclerLocationUid, `${consumer_and_conversation_uuid}_1`);

      // Delete all payment type notifications
      if (consumer_and_conversation_uuid in window.G_bi2_data.IV_consumer_conversation_payment_links.value === true) {
        for (let payment_link_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value[consumer_and_conversation_uuid]) {
          fs112Writer("d", currentBusinessUid, currentRecyclerLocationUid, payment_link_uid)
        }
      }
    },

    closeFinishConversationModal() {
      this.DV_finishConversationRating = null;
      this.DV_activeConversation = null;
      this.$refs.finishConversationModal.hide();
    },

    showQuotesPopupModal() {
      this.$refs.quotesPopupModal.showSelf();
    },

    showSelectCallingPhoneNumber() {
      this.DV_consumerNumbers = []
      let index = window.G_bi2_data.IV_al_consumer_contacts
      let profileData = window.G_bi2_data.IV_consumer_contacts.value[this.DV_activeConversation.consumer_uid]
      if (!profileData) { return }

      this.DV_consumerNumbers.push(profileData[index.primary_phone_number].phone_number || '')

      if (profileData[index.landline_numbers] && Object.keys(profileData[index.landline_numbers]).length > 0) {
        for (let number in profileData[index.landline_numbers]) {
          this.DV_consumerNumbers.push(profileData[index.landline_numbers][number].phone_number || "")
        }
      }

      this.DV_callingNumber = this.DV_consumerNumbers[0];
      if (this.DV_consumerNumbers.length > 1) {
        this.$refs.selectCallingPhoneNumber.show();
      } else {
        this.callCustomer(this.DV_callingNumber[0])
      }
    },

    closeSelectCallingPhoneNumberModal() {
      this.$refs.selectCallingPhoneNumber.hide();
    },

    async retrieveVerificationDocuments() {
      const CI = this;

      CI.DV_verificationFileLinks = [];
      
      if (!CI.DV_activeConversation) { return; }
 
      const consumerIndex = window.G_bi2_data.IV_al_consumer_contacts;
      let consumer_entry = window.G_bi2_data.IV_consumer_contacts.value[CI.DV_activeConversation.consumer_uid];
      
      CI.DV_previousConsumerUID = CI.DV_activeConversation.consumer_uid

      if (!consumer_entry[consumerIndex.verified_outputs].verification_documents) {
        let result = await retrieveIdentityVerificationDocuments(
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          CI.DV_activeConversation.consumer_uid
        ); 
        if (result.success === false) { return; }
      
        let file_links = result.response.file_links
        if (file_links.length === 0) { return; }
      
        CI.DV_verificationFileLinks = file_links;
      } else {
        CI.DV_verificationFileLinks = consumer_entry[consumerIndex.verified_outputs].verification_documents;
      }

    },

    async requestReviewLink() {
      const CI = this;

      if (!CI.DV_activeConversation) { return; }
 
      try {
        let result = await processReviewRequest(
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          CI.DV_activeConversation.consumer_uid,
          CI.DV_activeConversation.conversation_uid,
        );
        if (result.success === false) {
          CI.$awn.alert("Requesting Review Link URL failed.")
          return;
        }
      } catch(e) {
        CI.$awn.alert("Requesting Review Link URL failed.")
        return;
      }
    },
  },

  watch: {
    P_activeConversationUid() {
      this.setActiveConversation();
    }
  }
};
</script>
