import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  //DataProblem,
  //isPostalCodeValid,
  //isEmailValid,
  //OurLogger,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";
//const logger: OurLogger = OurLogger.getInstance();

import {
  getFirestore,
  CollectionReference,
  collection,
  DocumentData,
  doc,
  query,
  where,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type permissions_fs3Employees = {
  admin?: boolean;
  sales_person?: boolean;
  manager?: boolean;
  report_viewer?: boolean;
};

export type fs3Employees = {
  employee_uid: string;
  firebase_account_uid: string;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  extension?: string;
  average_employee_rating?: number;
  permissions: permissions_fs3Employees;
  is_deactivated?: boolean;
  is_super_admin?: boolean;
};

export function isValidData(data: fs3Employees): boolean {
  // if (
  //   data.average_employee_rating !== undefined &&
  //   !isValidRatingNumber(data.average_employee_rating)
  // ) {
  //   logger.error("Data validation error");
  //   return false;
  // }

  // if (!isEmailValid(data.email_address)) {
  //   logger.error("Data validation error");
  //   return false;
  // }

  return true;
}

export async function getDocFrom_fs3Employees(
  fs1_id: string,
  fs2_id: string,
  fs3_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs3Employees:getDocFrom_fs3Employees",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs3_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs3Employees",
      fs3_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function getDocByEmailFrom_fs3Employees(
  fs1_id: string,
  fs2_id: string,
  fs3_email: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs3Employees:getDocByEmailFrom_fs3Employees",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs3_email) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const collectionRef = collection(
    FirestoreDB, `fs1RecyclerBusiness/${fs1_id}/fs2RecyclerLocation/${fs2_id}/fs3Employees`
  );

  const queryRef = query(
    collectionRef,
    where("email_address", "==", fs3_email)
  );
  try {
    const querySnapshot = await getDocs(queryRef)
    querySnapshot.forEach((doc) => {
      result.doc_data = doc.data();
    });

    return await Promise.resolve(result);
  } catch (e) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }
}

export async function addDocIn_fs3Employees(
  fs1_id: string,
  fs2_id: string,
  data: fs3Employees
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs3Employees"
    )
  );

  data.employee_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs3Employees(
  fs1_id: string,
  fs2_id: string,
  fs3_id: string,
  data: fs3Employees
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs3Employees",
      fs3_id
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs3Employees(
  fs1_id: string,
  fs2_id: string,
  fs3_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs3Employees:removeDocFrom_fs3Employees ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs3_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs3Employees",
      fs3_id
    )
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
