<template>
  <div id="p1s3c4" class="h-full">
    <div v-if="activeTab == 'employeesList'" class="">

      <!-- Heading -->
      <div class="flex items-center justify-between m-3">

        <!-- Search Area -->
        <SearchBar v-model="DV_searchBarValue" containerClasses="w-5/12 my-2" inputClasses="w-full" />

        <div class="flex items-center justify-between">
          
          <!-- Toggle deactivated view -->
          <div v-if="C_canManageEmployee" class="flex items-center">
            <span class="flex-grow flex flex-col mr-4" id="toggleLabel">
              <span style="line-height: 1rem" class="font-bold text-gray-900">
                Show Deactivated Employees
              </span>
            </span>

            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_showDeactivatedEmployees ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_showDeactivatedEmployees"
                :class="[DV_showDeactivatedEmployees ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>
          </div>

          <!-- Create new employee button -->
          <button @click="activeTab = 'newEmployee'" class="btn btn-lime ml-4">
            Invite An Employee
          </button>
        </div>
      </div>

      <!-- Employees info table -->
      <div class="overflow-hidden border border-gray-400 rounded-xl m-3 h-[84vh] overflow-scroll hide-scrollbar">
        <table class="w-full rounded-xl text-center">
          <thead class="border-b">
            <tr class="text-left font-medium bg-gray-100">
              <th scope="col" class="text-center">Name</th>
              <th scope="col" class="text-center">Roles</th>
              <th scope="col" class="text-center">Email Address</th>
              <th scope="col" class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="emp in C_filteredEmployeesList" :key="emp.employee_uid" class="border-b bg-white h-20">
              <td>{{emp.first_name}} {{emp.last_name}}</td>
              <td>{{employeeRoles(emp)}}</td>
              <td>{{emp.email_address}}</td>
              <td>
                <div class="flex justify-center">
                  <button @click="editEmployee(emp)" class="btn btn-lime text-sm px-2 mr-2 mb-2 w-24">
                    Profile
                  </button>
                  <button v-if="C_canManageEmployee && emp.is_deactivated !== true" @click.stop="deactivateEmployee(emp)" class="btn btn-lime text-sm px-2 mb-2 mr-2 w-24">
                    Deactivate
                  </button>
                  <button v-if="C_canManageEmployee && emp.is_deactivated === true" @click.stop="reactivateEmployee(emp)" class="btn btn-lime text-sm px-2 mb-2 mr-2 w-24">
                    Reactivate
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="activeTab == 'newEmployee'">
      <p1s3c11EditNewEmployeeForm
        @event-back="activeTab = 'employeesList'"
        :employeeForm="activeTab"
        :P_mode="'new'"
      />
    </div>

    <div v-if="activeTab == 'editEmployee'">
      <p1s3c11EditNewEmployeeForm
        @event-back="activeTab = 'employeesList'"
        :employeeForm="activeTab"
        :employee="DV_selectedEmployee"
        :P_mode="'edit'"
      />
    </div>
  </div>
</template>

<script>
import p1s3c11EditNewEmployeeForm from "./p1s3c11EditNewEmployeeForm.vue";
import SearchBar from "../shared/SearchBar.vue";
import _ from 'lodash'
import AWN from "awesome-notifications";
import { fs3Writer } from "../../includes/firestore_writers/fs3_writer"

export default {
  name: "p1s3c4EmployeesManagement",

  components: { p1s3c11EditNewEmployeeForm, SearchBar},

  props: ["selectedRoleFilter"],

  data() {
    return {
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,
      activeTab: "employeesList",
      DV_employeesList: {},
      DV_selectedEmployee: {},
      DV_searchBarValue: "",
      DV_employeePermissions: {},
      DV_employeeInfo: {},
      DV_showDeactivatedEmployees: false,
    };
  },

  mounted() {
    const CI = this;

    CI.DV_employeesList = window.G_bi2_data.IV_recycler_employees.value;
    CI.DV_employeeInfo = window.G_bi2_data.IV_active_employee_info;
    CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
  },

  computed: {
    C_filteredEmployeesList() {
      const CI = this;
      let employees = [];

      for (let empUid in CI.DV_employeesList) {
        let emp = CI.DV_employeesList[empUid]
        let role_matched = false;
        let search_matched = false;

        if (emp.is_super_admin === true) { continue; }

        // Filtering the deactivated businesses based on the DV_showDeactivatedEmployees
        if (CI.DV_showDeactivatedEmployees) {
          if (emp.is_deactivated === false) {
            continue;
          }
        } else {
          if (emp.is_deactivated === true) {
            continue;
          }
        }

        if (CI.selectedRoleFilter.includes("all")) {
          role_matched = true;
        } if (CI.selectedRoleFilter.includes("admin") && emp["permissions"]["admin"]) { 
          role_matched = true;
        } else if (CI.selectedRoleFilter.includes("sales-person") && emp["permissions"]["sales_person"]) { 
          role_matched = true;
        } else if (CI.selectedRoleFilter.includes("manager") && emp["permissions"]["manager"]) { 
          role_matched = true;
        } else if (CI.selectedRoleFilter.includes("report-viewer") && emp["permissions"]["report_viewer"]) { 
          role_matched = true;
        }

        // Search
        if (CI.DV_searchBarValue !== undefined && CI.DV_searchBarValue !== null && CI.DV_searchBarValue.trim() !== "") {
          let searchText = CI.DV_searchBarValue.toLowerCase();

          if ((emp.first_name && emp.first_name.toLowerCase().includes(searchText)) ||
              (emp.last_name && emp.last_name.toLowerCase().includes(searchText)) ||
              (emp.email_address && emp.email_address.toLowerCase().includes(searchText))
            ) {
            search_matched = true;
          }
        } else {
          search_matched = true;
        }

        if (search_matched && role_matched) {
          employees.push(emp);
        }
      }
    
      return employees;
    },

    C_canManageEmployee() {
      const CI = this;

      return (CI.DV_employeePermissions.admin === true || CI.DV_employeePermissions.manager === true);
    }
  },

  methods: {
    employeeRoles(employee) {
      let roles = Object.keys(employee["permissions"])
      let allowed_roles = ""

      roles.forEach((role) => {
        if(employee["permissions"][role]) {
          allowed_roles += _.startCase(role.replace("_", " ")) + ", "
        }
      })

      return allowed_roles.slice(0, -2)
    },

    editEmployee(employee) {
      this.activeTab = 'editEmployee'
      this.DV_selectedEmployee = employee
    },

    deactivateEmployee(employee) {
      const CI = this;

      if (!CI.C_canManageEmployee) { return; }

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure you want to deactivate this employee?",
        
        // On OK
        async function() {
          fs3Writer(
            "e",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            employee.employee_uid,
            {is_deactivated: true}
          ).then((resp) => {
            if (resp.success === true) {
              CI.$awn.success("Employee deactivated successfully.")
            } else {
              CI.$awn.success("There was an issue while deactivating the employee.")
            }
          }).catch((error) => {
            CI.$awn.success("There was an issue while deactivating the employee.")
          });
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Deactivate Employee',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    reactivateEmployee(employee) {
      const CI = this;

      if (!CI.C_canManageEmployee) { return; }

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure you want to reactivate this employee?",
        
        // On OK
        async function() {
          fs3Writer(
            "e",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            employee.employee_uid,
            {is_deactivated: false}
          ).then((resp) => {
            if (resp.success === true) {
              CI.$awn.success("Employee reactivated successfully.")
            } else {
              CI.$awn.success("There was an issue while reactivating the employee.")
            }
          }).catch((error) => {
            CI.$awn.success("There was an issue while reactivating the employee.")
          });
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Reactivate Employee',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },
  },

  watch: {
    DV_employeeInfo: {
      handler() {
        const CI = this;
        CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
      },
      deep: true
    }
  }
};
</script>
