<template>
  <div class=" flex flex-col mx-auto">

    <!-- Login method buttons -->
    <div v-if="!DV_hideButtons" class="flex flex-col md:flex-row  items-center justify-around w-full mx-auto gap-6">
    <!-- Email/Password login button -->
        <button
         :class="{'ring-4': DV_activeLoginType == 2}" @click="setActiveLoginType(2)" class="btn btn-blue w-3/4 sm:w-fit drop-shadow-md h-full">Login With Email & Password</button>

      <!-- Email login button -->
        <button
        :class="{'ring-4': DV_activeLoginType == 1}" @click="setActiveLoginType(1)" class="btn btn-blue w-3/4 sm:w-fit drop-shadow-md h-full">Login With Email Link</button>

      <!-- New account button -->
        <button :class="{'ring-4': DV_activeLoginType == 3}" @click="gotoAccountSetup" class="shadow-2xl btn btn-blue w-3/4 sm:w-fit drop-shadow-md h-full">Sign Up for Synergy</button>
    </div>
    
    <!-- Email login form -->
    <div v-if="DV_activeLoginType == 1" class="grid grid-cols-11 mt-5 md:my-20">
      <div class="bg-white border border-gray-300 rounded-xl shadow-lg md:p-10 px-2 py-5 md:col-start-3 col-start-2 md:col-end-10 col-end-11">
        <h1 class="md:text-3xl text-xl text-center font-bold">Sign In By Receiving A Link In Your Email</h1>
        <div class="md:p-10 p-4">
          <div class="lg:w-1/2 w-full mx-auto">
            <label class="md:mt-10 mt-5 block text-gray-700 font-bold mb-2 text-left" for="email">Email: </label>
            <input autocomplete="off" v-model="DV_email" id="email" type="email" name="email" class="shadow w-full appearance-none border rounded p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75"> 
          </div>
          <div class="flex justify-center items-center pt-6">
            <button @click="loginViaEmail" class="btn btn-lime btn-w drop-shadow-md">Send Sign In Email</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Email/password login form -->
    <div v-if="DV_activeLoginType == 2" class="grid grid-cols-11 mt-5 md:my-20">
      <div class="bg-white border border-gray-300 rounded-xl shadow-lg md:p-10 px-2 py-5 md:col-start-3 col-start-2 md:col-end-10 col-end-11">
        <h1 class="md:text-3xl text-xl text-center font-bold">Sign In With Email And Password</h1>
        <div class="md:p-10 p-4">
          <div class="lg:w-1/2 w-full mx-auto">
            <label class="md:mt-10 mt-5 block text-gray-700 font-bold mb-2 text-left" for="email">Email: </label>
            <input autocomplete="off" v-model="DV_email" id="email" type="text" name="email" class="shadow w-full appearance-none border rounded p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75">  
          </div>
          <div class="lg:w-1/2 w-full mx-auto">
            <label class="mt-3 block text-gray-700 font-bold mb-2 text-left" for="password">Password: </label>
            <input autocomplete="off" v-model="DV_password" v-on:keydown.enter="emailPasswordLogin" id="password" type="password" name="password" class="shadow w-full appearance-none border rounded p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75"> 
          </div>
          <div class="grid items-center pt-6">
            <button @click="emailPasswordLogin" class="btn btn-lime btn-w drop-shadow-md justify-self-center">Sign In</button>
            <button @click="setActiveLoginType(4)" class="btn btn-lime btn-w drop-shadow-md mt-4 justify-self-center">Forgot Your Password?</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- New account form -->
    <div v-if="DV_activeLoginType == 3" class="grid grid-cols-11 mt-5 md:my-20">
      <div class="bg-white border border-gray-300 rounded-xl shadow-lg md:p-10 px-2 py-5 md:col-start-3 col-start-2 md:col-end-10 col-end-11">
        <h1 class="md:text-3xl text-xl text-center font-bold">Create Account</h1>
        <div class="md:p-10 p-4">
          <div class="lg:w-1/2 w-full mx-auto">
            <label class="md:mt-6 mt-3 block text-gray-700 font-bold mb-2 text-left" for="first-name">First Name: </label>
            <input autocomplete="off" v-model="DV_firstName" id="first-name" type="text" name="first-name" class="shadow w-full appearance-none border rounded p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75">  
          </div>
          <div class="lg:w-1/2 w-full mx-auto">
            <label class="mt-5 block text-gray-700 font-bold mb-2 text-left" for="last-name">Last Name: </label>
            <input autocomplete="off" v-model="DV_lastName" id="last-name" type="text" name="last-name" class="shadow w-full appearance-none border rounded p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75">  
          </div>
          <div class="lg:w-1/2 w-full mx-auto">
            <label class="mt-5 block text-gray-700 font-bold mb-2 text-left" for="email">Email: </label>
            <input autocomplete="off" v-model="DV_email" id="email" type="text" name="email" class="shadow w-full appearance-none border rounded p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75">  
          </div>
          <div class="lg:w-1/2 w-full mx-auto">
            <label class="mt-3 block text-gray-700 font-bold mb-2 text-left" for="password">Password: </label>
            <input autocomplete="off" v-model="DV_password" id="password" type="password" name="password" class="shadow w-full appearance-none border rounded p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75"> 
            <span v-show="!passwordRulesFulfilled" class="text-red-700 text-xs">Password must contain 1 uppercase letter, 1 special character -!"$%^@, and must be longer than 6 characters</span>
          </div>
          <div class="flex justify-center items-center pt-6">
            <button @click="createUserAccount" class="btn btn-lime btn-w drop-shadow-md">Create Account</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Reset password -->
    <div v-if="DV_activeLoginType == 4" class="grid grid-cols-11 my-20">
      <div class="bg-white border border-gray-300 rounded-xl shadow-lg md:p-10 px-2 py-5 md:col-start-3 col-start-2 md:col-end-10 col-end-11">
        <h1 class="md:text-3xl text-xl text-center font-bold">Password Reset</h1>
        <div class="md:p-10 p-4">
          <div class="lg:w-1/2 w-full mx-auto">
            <label class="md:mt-10 mt-5 block text-gray-700 font-bold mb-2 text-left" for="email">Email: </label>
            <input autocomplete="off" v-model="DV_email" id="email" type="text" name="email" class="shadow w-full appearance-none border rounded p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75">  
          </div>
          <div class="grid items-center pt-6">
            <button @click="resetPassword" class="btn btn-lime btn-w drop-shadow-md mt-4 justify-self-center">Send Password Reset Email</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataValidator from "../includes/bi1Datavalidation"
import {CR,RC} from "../includes/bi2SuccessCodes"
import {useRouter} from 'vue-router'
import m3RenderingSettings from "../mixins/m3RenderingSettings"
import { getDocFrom_fs140TemporaryAdminAccounts } from "../includes/firestore_schemas/fs140TemporaryAdminAccounts"


export default {
    name: 'p2LoginView',
        mixins: [ m3RenderingSettings ],

    data() { 
      return {
        DV_email: '',
        DV_activeLoginType: 2,
        DV_password: '',
        DV_passwordError: false,
        DV_emailError: false,
        DV_firstName: '',
        DV_lastName: '',
        DV_fs140_uid: '',
        DV_hideButtons: false,
      }
    },
    mounted() {
      window.document.title = "Synergy Auto Solutions - Login";

      this.$nextTick(() => {
        window.G_firebase_auth.setLoginPageCallbacks(
          this.signingInFeedback,
          this.signedInCallback,
          this.signInFailedCallback,
          this.passwordlessEmailSent,
          this.passwordlessEmailSendFailed,
          this.emailValid,
          this.emailNotValid,
          this.passwordValid,
          this.passwordNotValid,
          this.createUserFailed,
          this.passwordResetSent,
          this.passwordResetFailed
        ); 

      if(window.location.href.indexOf("staff_invite") !== -1) {
        let url_params = new URLSearchParams(window.location.search);
        const first_name = url_params.get('first_name');
        const last_name = url_params.get('last_name');
        const email = url_params.get('email');
        if(first_name !== undefined) { this.DV_firstName = first_name; }
        if(last_name !== undefined) { this.DV_lastName = last_name; }
        if(email !== undefined) { this.DV_email = email; }
        this.DV_hideButtons = true;
        this.DV_activeLoginType = 3;
      }


        //if the user was signing out reset the signout requested flag
        if (window.G_firebase_auth.IV_is_user_logged_in === true) {
          this.signedInCallback();
        }

        //if the user was signing out reset the signout requested flag
        if (window.G_firebase_auth.IV_signout_requested_flag === true) {
          window.G_firebase_auth.IV_signout_requested_flag = false;
        }
      });

      if (window.location.href.indexOf("id") !== -1) {
          let url_params = new URLSearchParams(window.location.search);
          const fs140_uid = url_params.get('id');

          this.loginAdmin(fs140_uid);
          return;
        }
    },

    computed: {
      passwordRulesFulfilled() {
        if ((this.DV_password <= 6) || (!/[A-Z]/.test(this.DV_password)) || (!/[-!"$%^@]/.test(this.DV_password))) {
          return false;
        }

        return true;
      }
    },

    methods: {
      setActiveLoginType(loginType){
        this.DV_activeLoginType = loginType;
      },

      signingInFeedback() {
        this.$awn.info('Loading Account Information.');
      },
      gotoAccountSetup() {
        window.G_vue_instance.$router.replace("/account-setup");
      },

      signedInCallback() {
        //this.$awn.info('Logged in successfully.');

        window.G_vue_instance.$router.replace("/sales-dashboard");
      },

      signInFailedCallback(error) {
        const CI = this;

        if (error[0].code === 'auth/user-not-found') {
          CI.DV_password = '';
          CI.$awn.alert('User account not found. Please create new user account.');
          CI.DV_activeLoginType = 3;
          return;
        }

        if (error[0].code === 'auth/wrong-password') {
          CI.DV_password = '';
          CI.$awn.alert('Incorrect password. Please try again.');
          return;
        }
          
        CI.$awn.alert("There was an error signing in account. Please try again.");
      },

      passwordlessEmailSent() {
        this.$awn.info('You should receive a sign in link in your email shortly.');
      },

      passwordlessEmailSendFailed(error) {
        this.$awn.alert("Error sending email:" + error);
      },

      emailValid() {
        this.DV_emailError = false;
      },

      emailNotValid() {
        this.DV_emailError = true;
      },

      passwordValid() {
        this.DV_passwordError = false;
      },

      passwordNotValid() {
        this.DV_passwordError = true;
      },

      createUserFailed(error) {
        let message = "There was an error creating account. "

        if (error[0].code === 'auth/email-already-in-use') {
          message += "Email address already in use."; 
        }
        if (error[0].code === 'auth/auth/invalid-email') {
          message += "Email address is not correct."; 
        }
        this.$awn.alert(message);
      },
      passwordResetSent() {
        this.$awn.info('You should receive a password reset email shortly.');
      },
      passwordResetFailed(error) {
        this.$awn.alert("Password reset failed with errorr: " + JSON.stringify(error));
      },
      loginViaEmail() {
        window.G_firebase_auth.emailLinkLogin(this.DV_email.toLowerCase())
      },
      emailPasswordLogin() {
        window.G_firebase_auth.emailPasswordLogin(this.DV_email.toLowerCase(), this.DV_password)
      },
      createUserAccount() {
        if (this.DV_firstName.trim() === "" || this.DV_lastName.trim() === "") {
          this.$awn.alert("Please fill first name and last name.")
          return;
        }
        let userName = this.DV_email.split("@")[0]
        let call_result = dataValidator.is_email_address(this.DV_email.toLowerCase());

        if (call_result[CR.success] !== RC.success || (/^\d+$/.test(userName))) {
          this.$awn.alert("Email address is invalid");
          return;
        }

        if (!this.passwordRulesFulfilled) {
          this.$awn.alert("Please set the correct password.")
          return;
        }

        window.G_firebase_auth.createUserAccount(this.DV_email.toLowerCase(), this.DV_password, this.DV_firstName, this.DV_lastName)
      },

      resetPassword() {
        window.G_firebase_auth.resetAccountPassword(this.DV_email.toLowerCase())
      },

      async loginAdmin(admin_uid) {
        let fs140_response = await getDocFrom_fs140TemporaryAdminAccounts(admin_uid);

        if (fs140_response.success === false) {
          console.log("Failed to retrieve fs140 document")
          return;
        }

        let doc_data = fs140_response.doc_data;

        window.G_firebase_auth.emailPasswordLogin(doc_data.admin_email, doc_data.admin_password);
      }
    }
}
</script>

<style scoped>
  .btn-w {
    min-width: 265px;
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    .btn-w {
    min-width: 100%;
  }
}
</style>