import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type fs140TemporaryAdminAccounts = {
  recycler_uid: string,
  recycler_location_uid: string,
  admin_email: string,
  admin_password: string,
  datetime_created: any,
  employee_uid: string,
};

// We assume that this function is fast and will not block.
export function isValidData(data: fs140TemporaryAdminAccounts): boolean {

  return true;
}

export async function getDocFrom_fs140TemporaryAdminAccounts(
  fs140_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs140TemporaryAdminAccounts:getDocFrom_fs140TemporaryAdminAccounts",
    success: true,
    doc_data: {},
  };

  if (!fs140_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs140TemporaryAdminAccounts"
    ),
    fs140_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs140TemporaryAdminAccounts(
  fs140_id: string,
  data: fs140TemporaryAdminAccounts
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const col_ref = collection(
    FirestoreDB,
    "fs140TemporaryAdminAccounts"
  )
  
  const doc_ref = doc(col_ref, fs140_id.toString());

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;

  return await Promise.resolve(result);
}

export async function setDocIn_fs140TemporaryAdminAccounts(
  fs140_id: string,
  data: fs140TemporaryAdminAccounts
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs140TemporaryAdminAccounts"
    ),
    fs140_id
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs140TemporaryAdminAccounts(
  fs140_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs140TemporaryAdminAccounts:removeDocFrom_fs140TemporaryAdminAccounts ",
    success: true,
  };

  if (!fs140_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs140TemporaryAdminAccounts"
    ),
    fs140_id
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
