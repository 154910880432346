<template>
    <div id="p1s1c1c4" class="border border-gray-400 p-2 rounded-xl sahdow-sm mb-4">
      <!-- vehicle entries -->
      <div class="flex-col">
        <span @click.stop="closeComponent">&#9650;</span>
      <span 
        v-for="entry in DV_consumerConversationDetails[DV_ccdIndexes.ma1_vehicles_and_parts_array]"
          class="block w-full truncate">
          <span v-if="entry[DV_ccdIndexes.ma1i_type] === 1">
            {{ entry[DV_ccdIndexes.ma1i_vehicle_year] + " " + entry[DV_ccdIndexes.ma1i_vehicle_make_name] + " " + entry[DV_ccdIndexes.ma1i_vehicle_model_name] }}
            
            <span v-if="entry[DV_ccdIndexes.mali_parts_description]">
            <br>{{entry[DV_ccdIndexes.mali_parts_description]  }}</span>
          </span>
        </span>
        
        <!-- </end> vehicle entries -->
        <!-- part number entires -->
        <span 
        v-for="entry in DV_consumerConversationDetails[DV_ccdIndexes.ma1_vehicles_and_parts_array]"
          class="block w-full truncate">              
          <span v-if="entry[DV_ccdIndexes.ma1i_type] === 2">
            Part Number: {{ entry[DV_ccdIndexes.ma1i_interchange_part_number] }}
          </span>
        </span>
        <!-- </end> part number entires -->
      </div>
   </div>
</template>  

<script>
export default {
  name: "p1s1c2c4ConversationDetails",
  props: ["P_conversationUuid"],

  data() {
    return {
      DV_ccdIndexes: window.G_bi2_data.IV_al_consumer_conversation_details,
      DV_consumerConversationDetails: []
    }
  },
  mounted() {
    this.loadConversationDetails();
  },

  methods: {
    loadConversationDetails() {
      if (this.P_conversationUuid in window.G_bi2_data.IV_consumer_conversation_details.value === false ) { return;}
      this.DV_consumerConversationDetails = window.G_bi2_data.IV_consumer_conversation_details.value[this.P_conversationUuid];
    },
    closeComponent() {
      this.$emit('close-component')
    }
  },

  watch: {
    P_conversationUuid() {
      this.loadConversationDetails();
  },
  }
};
</script>